import React from 'react';

import AboutSection from './about-section';

const SectionAbout = () => {
  return (
    <AboutSection title="About Me">
      <div className="mb-6">
        <p>I love writing code and Building Products. I occasionally dabble into art and design. And more than anything I love telling stories.</p>
        <p> You can follow my travels on Instagram. Feel free to reach me on Twitter.</p>
      </div>
    </AboutSection>
  );
};

export default SectionAbout;
