import React from 'react';

const classes = {
  wrapper: 'block pt-6',
  title: 'pb-6 sm:flex sm:items-center sm:justify-between sm:flex-wrap',
  heading:
    'text-3xl text-gray-900 font-medium',
  content: 'flex-none text-md text-gray-600 font-light md:flex-1',
};

const AboutSection = ({ title, children }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        <h2 className={classes.heading}>{title}</h2>
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default AboutSection;
