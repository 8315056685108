import { Link } from 'gatsby';
import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const classes = {
  wrapper: 'mb-6',
  name: 'font-semibold text-gray-900 pb-1',
  description: 'flex-none text-md text-gray-600 font-light md:flex-1',
};

const BlogSummaryItem = ({post, name, description, link = false, internal = false }) => {
  const image = getImage(post.thumbnail)

  return (
    <div className={classes.wrapper}>
      <Link to={link}>

        <GatsbyImage image={image} alt={post.title + "- Featured Shot"} />
        <h3 className='hover:underline hover:text-black'> {name} </h3>
        <p className={classes.description}>{description}</p>
    </Link>
    </div>
  );
};

export default BlogSummaryItem ;
