import { Link } from 'gatsby';
import React from 'react';

import BlogSection from './blog-section';
import BlogSummaryItem from './blog-summary-item';

const SectionBlog = ({ posts }) => {
  return (
    <div>
    <BlogSection title="Latest Posts">
      {posts.map((post) => (
        <BlogSummaryItem
          post={post.node.frontmatter}
          key={post.node.fields.slug}
          name={post.node.frontmatter.title}
          description={post.node.frontmatter.description}
          link={post.node.fields.slug}
          internal
        />
      ))}
    </BlogSection>
    {posts.length >= 3 && (
        <Link className="text-gray-500 text-sm hover:text-black" to="/blog">
          View all posts &rarr;
        </Link>
    )}
    </div>
  );
};

export default SectionBlog;
