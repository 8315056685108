import React from 'react';

const classes = {
  wrapper: 'block pt-6',
  title: 'pb-6 sm:flex sm:items-center sm:justify-between sm:flex-wrap border-b border-gray-200 mb-12',
  heading:
    'text-3xl text-gray-900 font-medium',
  content: 'col-span-3 grid sm:grid-cols-2 md:grid-cols-3 gap-6 sm:gap-y-8 lg:gap-x-8',
};

const BlogSection = ({ title, children }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        <h2 className={classes.heading}>{title}</h2>
        <p class="w-full flex-none text-gray-500 mt-2">My musings on technology, travel and personal life.</p>
      </div>
      
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default BlogSection;
